import React, { Component } from "react"
import NewLayout from '../layout/newLayout'
import SEO from "../components/seo"

export default class Contest extends Component {
    componentDidMount() {

        const contestScript = document.createElement("script")
        contestScript.src = "https://dnsl4xr6unrmf.cloudfront.net/js/campaign.js"
        contestScript.async = true
    
        document.body.appendChild(contestScript)
    }

    render() {
        let page = this.props.pageContext.page
        return (
            <NewLayout
                title={page.title}
                heroImage={page.heroImage}
                heroHeight={650}
            >
                {page.seo.title && <h1 style={{display:'none'}}>{page.seo.title}</h1>}
                <SEO page={page} />
                <div id="wyng-experience" data-wyng-id="5cffd4e896633000c642feaf" data-wyng-token="w8S0G8fxgs3AeXocWtM4U6MUtRIg00HBfVAh3J4M5cffd4e851c3cb01f2ba69b7"></div>
            </NewLayout>
        )
    }
}
